import { computed, effect, Injectable, OnDestroy, Signal, signal, ElementRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl } from '@angular/forms';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { filter, pipe, Subject, tap } from 'rxjs';

export interface LanguageList {
  code: 'en' | 'ru';
  label: string;
}
export type KeyboardAction =
  | { type: 'enter' | 'backspace' | 'show' } // No value allowed for these types
  | { type: 'key'; value: string } | null; // value is required for 'key'
@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  readonly onAction = rxMethod<KeyboardAction>(
    pipe(
      takeUntilDestroyed(),
      filter(() => {
        return this.activeControl() !== null;
      }),
      tap((action) => {
        console.log('<<<>>>', action)
        const control = this.activeControl();
        const el = this.activeElement();
        if (action) {
          if (['key', 'backspace'].includes(action.type)) {
            const currentCursorPosition = this.cursorPosition();
            const currentValue = control?.value || '';
            let newCursorPosition = 0;
            if (action.type === 'key') {
              const updatedValue = currentValue.slice(0, currentCursorPosition) +
                action.value +
                currentValue.slice(currentCursorPosition);
              console.log('setValue before', control?.value)
              control?.setValue(updatedValue);
              // control?.setValue(updatedValue, { emitEvent: control.updateOn !== 'blur' });
              console.log('setValue after', control?.value)
              newCursorPosition = currentCursorPosition + action.value.length;
            } else if (action.type === 'backspace') {
              const updatedValue = currentValue.slice(0, currentCursorPosition - 1) +
                currentValue.slice(currentCursorPosition);
              control?.setValue(updatedValue);
              newCursorPosition = currentCursorPosition - 1;
            }
            const inputEvent = new Event('input', { bubbles: true, cancelable: true });
            // Restore the cursor position
            // setTimeout(() => {
            //   if (el) {
            el?.nativeElement.setSelectionRange(newCursorPosition, newCursorPosition);
            //   }
            // });
            el?.nativeElement.dispatchEvent(inputEvent);
          }
        }
      })
    )
  );
  public action = signal<KeyboardAction>(null);
  cursorPosition = signal<number>(0);
  constructor() {
    this.onAction(this.action);
    // effect(() => {
    //   console.log('cursor position changed', this.cursorPosition())
    // })
  }
  languageList: LanguageList[] = [
    { code: 'en', label: 'Eng' },
    { code: 'ru', label: 'Рус' },
  ]
  shift = signal(false);
  alt = signal(false);
  keyboardRequested = signal(false);
  backspacePressed = signal(false);
  enterPressed = signal(false);

  currentLang = signal<'en' | 'ru'>('en');
  isNum = signal(false);

  currentLangIndex: Signal<number> = computed((): number => {
    return this.languageList.findIndex(lang => lang.code === this.currentLang());
  });
  nextLangIndex: Signal<number> = computed((): number => {
    return (this.currentLangIndex() + 1) % this.languageList.length;
  });

  activeControl = signal<AbstractControl | null>(null);
  activeElement = signal<ElementRef | null>(null);

  fireKeyboardRequested(show: boolean) {
    this.action.set({ type: 'show' });
  }

  fireKeyPressed(key: string) {
    this.action.set({
      type: 'key',
      value: key
    });
  }
  fireBackspacePressed() {
    this.action.set({ type: 'backspace' })
  }
  fireEnterPressed() {
    this.action.set({ type: 'enter' })
  }

  setLangKeyboard() {
    this.currentLang.set(this.languageList[this.nextLangIndex()].code);
    this.alt.set(false);
    this.shift.set(false);
  }

  getNextLanguageLabel() {
    return this.languageList[this.nextLangIndex()].label || '?';
  }
  setActiveInput(control: AbstractControl | null, el: ElementRef | null) {
    this.activeControl.set(control);
    this.activeElement.set(el);
  }
}
