import { Directive, ElementRef, effect, inject } from '@angular/core';
import { CstcStore } from 'src/app/store/state.store';

@Directive({
  selector: '[adjustFont]',
  standalone: true,
})
export class AdjustFontDirective {
  state = inject(CstcStore);
  element = inject(ElementRef);

  wordsListLength = 6;
  wordItemLength = 11;

  constructor() {
    effect(() => {
      // isWideScreen=true/false в зависимости от ширины экрана, получаем из store
      const isWideScreen = this.state.isWideScreen();
      this.adjustFontSize(isWideScreen);
    })
  }

  adjustFontSize(isWideScreen: boolean): void {
    /**
     * @param textContent - текстовое содержание блока
     * @param words - слова в textContent разделенные пробелом
     * @param manyWords - кол-во слов
     * @param longWord - масимальное кол-во символов в одном слове
     * 
     * manyWords = words.length > this.wordsListLength; - если кол-во слов больше wordsListLength (в данном случае 6)
     * const longWord = words.some(word => word.length >= this.wordItemLength); - проверяем есть ли в массиве слов хотя бы одно больше чем wordItemLength (в данном случае 11)
     * Когда применяется:
     * - всегда если кол-во слов больше wordsListLength
     * - если это мобильный и есть хотя бы одно слово больше wordItemLength
     * Если не одно из условий не удовлетворяется, то сбрасываем стили на default (то есть равно пустой строке)
     */

    const element: HTMLElement = this.element.nativeElement;
    const textContent: string = element.textContent || '';

    if (textContent.trim() !== '') {
      const words: string[] = textContent.split(' ');
      const manyWords = words.length > this.wordsListLength;
      const longWord = words.some(word => word.length >= this.wordItemLength);

      if (manyWords || (!isWideScreen && longWord)) {
        element.style.fontSize = '20px';
        element.style.lineHeight = '120%';
      } else {
        element.style.fontSize = '';
        element.style.lineHeight = '';
      }
    }
  }
}
