import { Directive, HostListener, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from 'src/app/services/platform/platform.service';
declare var cordova: any;

@Directive({
  selector: '[urlHandler]',
  standalone: true,
})
export class UrlHandlerDirective {
  private router = inject(Router);
  private platformSvc = inject(PlatformService);
  inAppBrowserRef: any;
  @Input() urlHandler!: string;

  private wasDragged = false;

  @HostListener('mousedown')
  @HostListener('touchstart')
  onMouseDown() {
    this.wasDragged = false;
  }

  @HostListener('mousemove')
  @HostListener('touchmove')
  onMouseMove() {
    this.wasDragged = true;
  }

  @HostListener('click', ['$event'])
  handleUrl(event: Event): void {
    if (this.wasDragged) {
      event.preventDefault();
      return;
    }
    event.preventDefault();

    const isExternalUrl = this.isExternal(this.urlHandler);

    if (isExternalUrl) {
      this.handleExternalUrl(this.urlHandler);
    } else {
      this.handleInternalUrl(this.urlHandler);
    }
  }

  private isExternal(url: string): boolean {
    return /^(http|https):\/\//.test(url);
  }

  private handleInternalUrl(url: string): void {
    this.router.navigateByUrl(url);
  }

  private handleExternalUrl(url: string): void {
    const isMobileApp = this.platformSvc.isMobileApp();

    if (isMobileApp) {
      cordova.InAppBrowser.open(url, '_system');
    } else {
      window.open(url, '_blank');
    }
  }
}
