<div class="content-wrapper" #contentWrapper (click)="$event.stopPropagation()">
    <header class="header">
        @if (content()?.title) {
            <h2 class="title">{{content()?.title}}</h2>
        }
        @if (content()?.hasClose) {
            @if (kiosk()) {
                <a cstc-button="outline" class="close" (click)="closeDialog()">
                    <span iconName="close" class="icon"></span>
                </a>
            }@else {
                <div class="close" (click)="closeDialog()">
                    <span iconName="close" class="icon"></span>
                </div>
            }
        }
    </header>
    <div class="overlay-start" [class.hidden]="!scrollStart()"></div>
    <div class="content">
        @if (confirmDialogSvc.isLoading()) {
            <cstc-loader class="inline"/>
        }@else if (content()?.isHtml) {
            <div class="htmlOutput" [innerHTML]="(content()?.content || '') | htmlWithUrl:googleFormUrl"></div>
        }@else {
            <p class="text">{{content()?.content}}</p>
        }
    </div>
    <footer class="footer">
        @if (content()?.buttonConfirm) {
            <a cstc-button="secondary" class="confirm" (click)="confirm()">{{content()?.buttonConfirm}} {{remainingTime()}} сек</a>
        }
        @if (content()?.buttonCancel) {
            <a cstc-button="primary"  class="cancel" (click)="closeDialog()">{{content()?.buttonCancel}}</a>
        }
    </footer>
    <!-- @if(content()?.hasTimer && remainingTime() !== null){
        <p class="timer text italic">{{content()?.timerMessage || 'Осталось'}}: {{remainingTime()}} сек</p>
    } -->
    <div class="overlay-end" [class.hidden]="!scrollEnd()"></div>
</div>
<!-- http://localhost:4200/qr/D27-923607114 -->