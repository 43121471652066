import { AfterViewInit, ChangeDetectionStrategy, Component, computed, effect, ElementRef, HostBinding, inject, OnDestroy, signal, ViewChild } from '@angular/core';
import { ConfirmDialogService } from './confirm-dialog.service';
import { ButtonComponent } from '../button/button.component';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';
import { LoaderComponent } from '../loader/loader.component';
import { HtmlWithUrlPipe } from 'src/app/pipes/html-with-url.pipe';
import { SvgIconDirective } from '../../directives/svg-icon.directive';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'cstc-confirm-dialog',
  standalone: true,
  imports: [
    ButtonComponent,
    HtmlWithUrlPipe,
    LoaderComponent,
    SvgIconDirective,
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent implements AfterViewInit, OnDestroy {
  state = inject(CstcStore);
  confirmDialogSvc = inject(ConfirmDialogService);

  isOpen = this.confirmDialogSvc.isOpen;
  content = this.confirmDialogSvc.dialogContent;
  kiosk = this.state.isKiosk;

  googleFormUrl: string | null = null;
  scrollEnd = signal(false);
  scrollStart = signal(false);
  private timerInterval: any;
  private currentTime = signal<number | null>(null);

  remainingTime = computed(() => {
    const hasTimer = this.content()?.hasTimer ?? false;
    const timerValue = this.content()?.timerValue;
    const current = this.currentTime();

    if (hasTimer && timerValue !== undefined && this.isOpen()) {
      return current !== null ? current : timerValue;
    }
    return null;
  });

  @ViewChild('contentWrapper') contentWrapper!: ElementRef;

  // private touchStartY: number | null = null;
  // private touchMoved: boolean = false;
  // private touchIndex: number = 100;
  // private canCloseBottom = signal(false);
  // private canCloseTop = signal(false);
  // private scrollListener: () => void = () => this.updateScrollState();
  // private touchStartListener: (event: TouchEvent) => void = (event) => this.handleTouchStart(event);
  // private touchMoveListener: (event: TouchEvent) => void = (event) => this.handleTouchMove(event);
  // private touchEndListener: (event: TouchEvent) => void = (event) => this.handleTouchEnd(event);

  @HostBinding('class') get hostClass() {
    return {
      'active': this.isOpen()
    }
  }

  constructor() {
    effect(() => {
      if (this.content() && this.isOpen()) {
        setTimeout(() => this.updateScrollState(), 0);
        if (this.content()?.hasTimer) {
          this.startTimer();
        }
      } else {
        this.stopTimer();
      }
    }, { allowSignalWrites: true });
  }

  ngAfterViewInit() {
    // const element = this.contentWrapper.nativeElement;
    this.updateScrollState();
    this.contentWrapper.nativeElement.addEventListener('scroll', () => this.updateScrollState());

    // element.addEventListener('scroll', this.scrollListener);
    // element.addEventListener('touchstart', this.touchStartListener);
    // element.addEventListener('touchmove', this.touchMoveListener);
    // element.addEventListener('touchend', this.touchEndListener);
  }

  ngOnDestroy() {
    this.stopTimer();
    this.contentWrapper.nativeElement.removeEventListener('scroll', () => this.updateScrollState());
    // const element = this.contentWrapper?.nativeElement;

    // if (element) {
    //   element.removeEventListener('scroll', this.scrollListener);
    //   element.removeEventListener('touchstart', this.touchStartListener);
    //   element.removeEventListener('touchmove', this.touchMoveListener);
    //   element.removeEventListener('touchend', this.touchEndListener);
    // }
  }

  updateScrollState() {
    const element = this.contentWrapper.nativeElement;
    if (!element) return;

    const isScrollable = element.scrollHeight > element.clientHeight;
    const isAtBottom = element.scrollTop + element.clientHeight >= element.scrollHeight - 1;
    const isAtTop = element.scrollTop <= 1;

    this.scrollEnd.set(isScrollable && !isAtBottom);
    this.scrollStart.set(isScrollable && !isAtTop);

    // if (isAtBottom) {
    //   this.canCloseBottom.set(true);
    // }
    // if (isAtTop) {
    //   this.canCloseTop.set(true);
    // }
  }
  /*
  handleTouchStart(event: TouchEvent) {
    this.touchStartY = event.touches[0].clientY;
    this.touchMoved = false;
  }

  handleTouchMove(event: TouchEvent) {

    if (this.touchStartY === null) return;
    this.touchMoved = true;

    const currentY = event.touches[0].clientY;
    const deltaY = currentY - this.touchStartY;

    // if (!this.scrollEnd() && deltaY < 0) {
    //   event.preventDefault();
    // } else if (this.scrollStart() && deltaY > 0) {
    //   event.preventDefault();
    // }
    if (this.canCloseBottom() && !this.scrollEnd() && deltaY < 0 && event.cancelable) {
      event.preventDefault();
    }
    if (this.canCloseTop() && !this.scrollStart() && deltaY > 0 && event.cancelable) {
      event.preventDefault();
    }
  }

  handleTouchEnd(event: TouchEvent) {
    if (this.touchStartY === null || !this.touchMoved) return;

    const currentY = event.changedTouches[0].clientY;
    const deltaY = currentY - this.touchStartY;

    // if (!this.scrollEnd() && deltaY < -this.touchIndex) {
    //   this.closeDialog();
    // } else if (!this.scrollStart() && deltaY > this.touchIndex) {
    //   this.closeDialog();
    // }
    if (this.canCloseBottom && !this.scrollEnd() && deltaY < -this.touchIndex) {
      this.closeDialog();
      this.canCloseBottom.set(false);
    }
    if (this.canCloseTop && !this.scrollStart() && deltaY > this.touchIndex) {
      this.closeDialog();
      this.canCloseTop.set(false);
    }

    this.touchStartY = null;
    this.touchMoved = false;

    if (deltaY > 0 && this.scrollEnd()) {
      this.canCloseBottom.set(false);
    }
    if (deltaY < 0 && this.scrollStart()) {
      this.canCloseTop.set(false);
    }
  }
  */

  startTimer() {
    this.stopTimer();

    const timerValue = this.content()?.timerValue;
    if (timerValue !== undefined && timerValue > 0) {
      this.currentTime.set(timerValue);
      this.timerInterval = setInterval(() => {
        const current = this.currentTime();
        if (current !== null && current > 0) {
          this.currentTime.set(current - 1);
        } else {
          this.stopTimer();
          this.closeDialog();
        }
      }, 1000)
    }
  }

  stopTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
    this.currentTime.set(null);
  }

  closeDialog() {
    this.confirmDialogSvc.close();
  }

  confirm() {
    try {
      this.content()?.confirmAction?.();
    } catch (err) {
      console.error('confirm failed: ', err);
    }
    this.closeDialog();
  }
}
