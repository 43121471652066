import { Directive, effect, ElementRef, HostListener, inject, OnInit, signal } from '@angular/core';
import { KeyboardService } from './keyboard.service';
import { Subscription } from 'rxjs';
import { AbstractControl, FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[oskInput]',
  standalone: true,
})
export class OskInputDirective implements OnInit {
  private formControl?: AbstractControl;
  private formControlElement = inject(ElementRef);
  private keyboard = inject(KeyboardService);
  private ngControl = inject(NgControl);
  private el = inject(ElementRef<HTMLInputElement>);
  constructor() {
    // effect(() => {
    //   const key = this.keyboard.keyPressed;
    //   const activeElement = this.keyboard.activeElement();
    //   console.log(13);
    //   if (key && activeElement === this.el.nativeElement) {
    //     this.onKey(key());

    //     this.keyboard.keyPressed.set('');
    //   }
    // }, { allowSignalWrites: true });

    // effect(() => {
    //   const backspacePressed = this.keyboard.backspacePressed();
    //   const activeElement = this.keyboard.activeElement();
    //   if (backspacePressed && activeElement === this.el.nativeElement) {
    //     this.onBackspace();
    //     this.keyboard.backspacePressed.set(false);
    //   }
    // }, { allowSignalWrites: true });

    // effect(() => {
    //   const enterPressed = this.keyboard.enterPressed();
    //   const activeElement = this.keyboard.activeElement();
    //   if (enterPressed && activeElement === this.el.nativeElement) {
    //     this.onEnter();
    //     this.keyboard.enterPressed.set(false);
    //   }
    // }, { allowSignalWrites: true });
  }

  ngOnInit() {
    if (this.ngControl && this.ngControl.control) {
      this.formControl = this.ngControl.control;
    }
  }
  /**
   * Tracks and logs the cursor position when the user interacts with the input.
   */
  @HostListener('click')
  @HostListener('input')
  private trackCursorPosition(): void {
    const inputElement = this.el.nativeElement;
    const currentCursorPosition = inputElement.selectionStart ?? 0;
    this.keyboard.cursorPosition.set(currentCursorPosition); // Update the signal
  }
  @HostListener("focus")
  private onFocus() {
    if (this.formControl) {
      const inputElement = this.el.nativeElement;
      this.keyboard.setActiveInput(this.formControl, this.el);
      const initialCursorPosition = inputElement.selectionStart ?? 0;
      this.keyboard.cursorPosition.set(initialCursorPosition); // Update the signal

      const inputType = inputElement.type;
      if (inputType === 'tel') {
        this.keyboard.isNum.set(true);
      } else {
        this.keyboard.isNum.set(false);
      }
    }


    // this.keyboard.fireKeyboardRequested(true);
  }

  @HostListener("blur")
  private onBlur() {
    this.keyboard.setActiveInput(null, null);

    // if (this.formControl && (this.formControl as FormControl).updateOn === 'blur') {
    //   this.formControl.updateValueAndValidity();
    // }
  }

  private onKey(key: string) {
    // console.log('key pressed', key);
    // const element = this.el.nativeElement as HTMLInputElement;
    // const start = element.selectionStart ?? 0;
    // const end = element.selectionEnd ?? 0;
    // console.log(key,'<<');
    // this.measure.textContent = element.value.substring(0, start) + key;
    // element.value = element.value.substring(0, start) + key + element.value.substring(end);
    // element.selectionStart = element.selectionEnd = start + 1;

    this.updateScrollPosition();

    // element.dispatchEvent(new Event('input', { bubbles: true }));
  }

  private onBackspace() {
    // const element = this.el.nativeElement as HTMLInputElement;
    // let start = element.selectionStart ?? 0;
    // const end = element.selectionEnd ?? 0;

    // if (start == 0) {
    //   return;
    // }

    // if (start == end) {
    //   start--;
    // }

    // this.measure.textContent = element.value.substring(0, start);
    // element.value = element.value.substring(0, start) + element.value.substring(end);
    // element.selectionStart = element.selectionEnd = start;

    this.updateScrollPosition();
  }

  private updateScrollPosition() {
    // const element = this.el.nativeElement as HTMLInputElement;
    // element.scrollLeft = this.measure.offsetWidth - (element.clientWidth - 10);
  }

  private onEnter() {
    alert("Enter");
  }
}
