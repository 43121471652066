import { Inject, Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'htmlWithUrl',
  standalone: true
})
export class HtmlWithUrlPipe implements PipeTransform {
  private sanitizer = inject(DomSanitizer);

  transform(
    value: string | undefined,
    googleFormUrl?: string | null
  ): SafeHtml {
    if (!value) {
      return this.sanitizer.bypassSecurityTrustHtml('');
    }

    const replacedValue = googleFormUrl
      ? this.replaceWithButton(value, googleFormUrl)
      : value;

    // Create a temporary container element to manipulate the HTML
    // const container = document.createElement('div');
    // container.innerHTML = replacedValue;
    // Add the urlHandler attribute to all <a> tags
    // const links = container.querySelectorAll('a');
    // links.forEach((link) => {
    //   link.setAttribute('urlHandler', link.getAttribute('href') || '');
    // });
    // Return the transformed HTML as SafeHtml
    // return this.sanitizer.bypassSecurityTrustHtml(container.innerHTML);

    const parser = new DOMParser();
    const doc = parser.parseFromString(replacedValue, 'text/html');
    const links = doc.querySelectorAll('a');

    links.forEach((link) => {
      const href = link.getAttribute('href') || '';
      link.setAttribute('urlHandler', href);
    })

    return this.sanitizer.bypassSecurityTrustHtml(doc.body.innerHTML);
  }

  private replaceWithButton(value: string, googleFormUrl: string): string {
    return value.replaceAll(
      '{{ google_form }}',
      `<a class="btn" target="_blank" href="${this.escapeHtml(googleFormUrl)}">Заполнить форму *</a>`
    );
  }

  private escapeHtml(unsafe: string): string {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }
}
