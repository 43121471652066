import { HostBinding, inject, Injectable, signal } from '@angular/core';
import { PlatformService } from 'src/app/services/platform/platform.service';

/**
 * Интерфейс, в котором мы описывает необходимые для диалогового окна параметры
 * @param hasClose - если true, то в header будет добавляться кнопка закрытия
 * @param title - название окна
 * @param content - текст внутри окна
 * @param isHtml - если true, то content будет интерпретироваться как html
 * @param buttonConfirm - для запуска confirmAction
 * @param buttonCancel - кнопка закрытия окна
 * @param confirmAction - метод, который запустится после нажатия на buttonConfirm
 * При желании функционал можно расширить
 */
export interface ConfirmDialogContent {
  hasClose?: boolean;
  title?: string;
  content: string | null;
  isHtml?: boolean;
  buttonConfirm?: string;
  buttonCancel?: string;
  hasTimer?: boolean;
  timerMessage?: string;
  timerValue?: number;
  confirmAction?: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {
  /** сигнал, который отвчает за открытие/закрытие окна */
  isOpen = signal(false);
  isLoading = signal(false);

  /** сигнал для хранения содержимого модального окна */
  dialogContent = signal<ConfirmDialogContent | null>(null);

  /** метод для открытия модального окна */
  open(hasClose: boolean = false, title: string, content: string | null, isHtml: boolean = false, buttonConfirm?: string, buttonCancel?: string, hasTimer: boolean = false, timerMessage?: string, timerValue?: number, confirmAction?: () => void) {
    this.dialogContent.set({ hasClose, title, content, isHtml, buttonConfirm, buttonCancel, hasTimer, timerMessage, timerValue, confirmAction });
    this.isOpen.set(true);
    this.isLoading.set(false);
  }

  /** метод для закрытия модального окна */
  close() {
    this.isOpen.set(false);
    /** очищаем контент после небольшой задержки для анимации */
    setTimeout(() => this.dialogContent.set(null), 300);
  }

  showLoading(title: string = 'Загрузка...') {
    this.dialogContent.set({ title, content: null, isHtml: false });
    this.isOpen.set(true);
    this.isLoading.set(false);
  }
}

/**
 * Использование в компоненте:
 * 
 * - добавить компонент ConfirmDialogContent в imports
 * - добавить <cstc-confirm-dialog/> в шаблон и кнопку для вызова окна (openDialog())
 * - подключить к компоненту сервис confirmDialogSvc = inject(ConfirmDialogService);
 * - содать метод для открытия окна с параметрами соответственно к интерфейсу:
 *  openDialog(event?: MouseEvent) {
     if (event) {
       event.preventDefault();
     }
     this.confirmDialogSvc.open(
       'Отменить заказ',
       'Вы уверены что хотите отменить текущий заказ?',
       false,
       'Новый заказ',
       'Продолжить',
       () => {
         console.log('Тут будет что-то для того чтоб начать новый заказ');
       },
     )
   }
 */
