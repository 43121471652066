import { Directive, effect, HostBinding, signal } from '@angular/core';
import { interval } from 'rxjs';

@Directive({
  selector: '[neonPulse]',
  standalone: true,
})
export class NeonPulseDirective {
  isPulse = signal(true);

  @HostBinding('class') get hostClass() {
    return {
      'pulse': this.isPulse(),
    }
  }

  /**
   * effect запускает событие каждые 75 секунд ("cycleDaration") - это общее время цикла
   * по истечении 60 секунд (общее время цикла - время мигания) устанавливаем isPulse в значение false, что приводит к добавлению класса "kinozoom"
   * по истечении 15 секунд устанавливаем isPulse в значение true, т.е. восстанавливаем изначальное состояние, когда нет мигания
   */
  constructor() {
    effect(() => {
      const pulseDuration = 15000;
      const cycleDaration = 75000;
      interval(cycleDaration).subscribe(() => {
        this.isPulse.set(false);
        setTimeout(() => this.isPulse.set(true), pulseDuration);
      });
    })
  }
}
